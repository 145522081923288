<template>
  <b-card-code :title="$t('permissions')">
    <b-tabs>
      <b-tab :title="$t('Speed monitor')" active v-if="!isLocalLayout()">
        <advanced-table
          :tableColumns="devicesColumns"
          :dataAction="actionGet"
          :dataURLParams="{ type: 'speeding', id: $route.params.id }"
          columnSortBy="read"
          :columnIsSortDirDesc="true"
          ref="speedingPermissions"
        >
          <template #cell(read)="data">
            <b-form-checkbox
              v-model="data.item.read"
              name="checkbox-1"
              :value="1"
              :unchecked-value="0"
              :disabled="data.item.readDisabled"
            >
            </b-form-checkbox>
          </template>
          <template #cell(manage)="data">
            <b-form-checkbox
              v-model="data.item.manage"
              name="checkbox-1"
              :value="1"
              :unchecked-value="0"
              :disabled="data.item.manageDisabled"
            >
            </b-form-checkbox>
          </template>
        </advanced-table>
      </b-tab>
      <b-tab :title="$t('Cyclist counter')" v-if="!isLocalLayout()">
        <advanced-table
          :tableColumns="devicesColumns"
          :dataAction="actionGet"
          :dataURLParams="{ type: 'cyclist', id: $route.params.id }"
          ref="cyclistPermissions"
        >
          <template #cell(read)="data">
            <b-form-checkbox
              v-model="data.item.read"
              name="checkbox-1"
              :value="1"
              :unchecked-value="0"
            >
            </b-form-checkbox>
          </template>
          <template #cell(manage)="data">
            <b-form-checkbox
              v-model="data.item.manage"
              name="checkbox-1"
              :value="1"
              :unchecked-value="0"
            >
            </b-form-checkbox>
          </template>
        </advanced-table>
      </b-tab>
      <b-tab :title="$t('Vehicle counter')" v-if="!isLocalLayout()">
        <advanced-table
          :tableColumns="devicesColumns"
          :dataAction="actionGet"
          :dataURLParams="{ type: 'vehicle', id: $route.params.id }"
          columnSortBy="read"
          :columnIsSortDirDesc="true"
          ref="vehiclePermissions"
        >
          <template #cell(read)="data">
            <b-form-checkbox
              v-model="data.item.read"
              name="checkbox-1"
              :value="1"
              :unchecked-value="0"
              :disabled="data.item.readDisabled"
            >
            </b-form-checkbox>
          </template>
          <template #cell(manage)="data">
            <b-form-checkbox
              v-model="data.item.manage"
              name="checkbox-1"
              :value="1"
              :unchecked-value="0"
              :disabled="data.item.manageDisabled"
            >
            </b-form-checkbox>
          </template>
        </advanced-table>
      </b-tab>
      <b-tab :title="$t('radars')">
        <advanced-table
          :tableColumns="devicesColumns"
          :dataAction="actionGet"
          :dataURLParams="{ type: 'radar', id: $route.params.id }"
          ref="radarPermissions"
        >
          <template #cell(read)="data">
            <b-form-checkbox
              v-model="data.item.read"
              name="checkbox-1"
              :value="1"
              :unchecked-value="0"
            >
            </b-form-checkbox>
          </template>
          <template #cell(manage)="data">
            <b-form-checkbox
              v-model="data.item.manage"
              name="checkbox-1"
              :value="1"
              :unchecked-value="0"
            >
            </b-form-checkbox>
          </template>
        </advanced-table>
      </b-tab>
    </b-tabs>
    <b-button variant="primary" @click="savePermissions">{{
      $t('save')
    }}</b-button>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code';
import { BTab, BTabs, BCardText, BFormCheckbox, BButton } from 'bootstrap-vue';
import AdvancedTable from '@/views/components/AdvancedTable.vue';
import i18n from '@/libs/i18n';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BCardCode,
    BTab,
    BCardText,
    BTabs,
    AdvancedTable,
    BFormCheckbox,
    BButton,
  },
  props: {
    actionGet: {
      type: String,
    },
    actionSave: {
      type: String,
    },
  },
  data() {
    return {
      devicesColumns: [
        { key: 'name', label: i18n.tc('device_title'), sortable: true },
        { key: 'manage', label: i18n.tc('device_manage'), sortable: true },
        { key: 'read', label: i18n.tc('device_read'), sortable: true },
      ],
    };
  },
  methods: {
    isLocalLayout() {
      return this.$store.state.appConfig.layout.isLocalLayout;
    },
    savePermissions() {
      let arr = this.$refs.radarPermissions.data;
      if (!this.isLocalLayout())
        arr = arr.concat(
          this.$refs.speedingPermissions.data,
          this.$refs.cyclistPermissions.data,
          this.$refs.vehiclePermissions.data,
        );

      let data = {
        data: arr,
        id: this.$route.params.id,
      };
      //data.id = this.$route.params.id;
      this.$store.dispatch(this.actionSave, data).then(() => {
        this.refresh();
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('success'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
            text: this.$t('update_success_descirption'),
          },
        });
      });
    },
    refresh() {
      if (this.$refs.radarPermissions) this.$refs.radarPermissions.refresh();
      if (this.$refs.speedingPermissions)
        this.$refs.speedingPermissions.refresh();
      if (this.$refs.cyclistPermissions)
        this.$refs.cyclistPermissions.refresh();
    },
  },
};
</script>
