<template>
  <b-form-group :label="label" :label-for="label.toLowerCase()">
    <validation-provider #default="{ errors }" :name="label" :rules="rules">
      <b-form-input
        :id="label.toLowerCase()"
        :state="errors.length > 0 ? false : null"
        :placeholder="label"
        v-model="inputVal"
        :type="type"
        :disabled="disabled"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BCard, BFormInput, BFormGroup } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
export default {
  props: {
    value: { type: String, required: false },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style></style>
